:root {
    --neutral: #1e0048;
    --selection: #1e0048;
    --neutral-light: #d8d4e1;
    --neutral-lighter: #e5e3ea;
    --neutral-secondary: #71688d; /*#8F89A7;*/
    --accent-hover: #00ba8d;
    --accent: #008565;
    --background: #f4f6f8;

    --red-error: #dd2222;

    --destructive: #e17366;
}
