:root {
    --button-height: 60px;
    --button-border-radius: 0.5rem;
    --button-margin-v: 4px;
    --marginXS: 8px;
    --marginS: 16px;
    --marginM: 24px;
    --marginL: 32px;
    --marginXL: 40px;
    --marginXXL: 48px;
    --left-column-width: 380px;
    --number-size: var(--marginXL);

    --timing: 0.3s;
    --easing: cubic-bezier(0.075, 0.82, 0.165, 1);
    height: 100%;
}

body {
    box-sizing: border-box;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

#root {
    min-height: 100%;
    flex: 1;
    display: flex;
}
.app {
    min-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

footer {
    border-top: 1px solid var(--neutral-lighter);
    bottom: 0;
    position: relative;
    width: 100%;
    text-align: center;
}

.main {
    flex: 1;
}

h4 {
    text-transform: uppercase;
    font-weight: 700;
}

.mobile-only {
    display: none;
}

.hidden {
    visibility: collapse;
    height: 0;
}
.main-container {
    margin: 0 var(--marginS);
    position: relative;
    flex: 1;
}

p,
ul {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

.information-box ul {
    padding-inline-start: 1rem;
    margin-block-start: auto;
}
.information-box {
    overflow-wrap: anywhere;
}

.information-box img {
    max-width: 100%;
}
.bold {
    font-weight: bold;
}

.map {
    display: none;
    border: 0;
}

.button {
    border-radius: 4px;
    background-color: var(--neutral-lighter);
    border: none;
    color: var(--neutral);
    font-weight: 700;
    font-size: 1rem;
    width: 100%;
    cursor: pointer;
    line-height: 2rem;
    transition: var(--timing);
}

.button:not([disabled]):hover {
    background-color: var(--neutral-lighter);
}

.button.secondary {
    line-height: 4rem;
    border-radius: 50vh;
    margin: var(--marginXS) 0;
}

.primary,
.button.primary {
    display: inline-block;
    border: 0;
    background-color: var(--accent);
    color: white;
    line-height: 4rem;
    border-radius: 50vh;
    font-size: 1rem;
    font-weight: 600;
    font-family:
        'Poppins',
        -apple-system,
        BlinkMacSystemFont,
        'Helvetica Neue',
        sans-serif;
    margin: var(--marginXS) 0;
    padding: 0 2rem;
}

.primary.destructive {
    background-color: var(--destructive);
    min-width: 200px;
    cursor: pointer;
}

.button.primary:not([disabled]):hover {
    background-color: var(--accent-hover);
}

.button-grid-title {
    margin: var(--marginS) 0 var(--marginS) 0;
}

a {
    text-decoration: none;
    font-weight: 600;
    color: var(--accent);
}

address {
    font-style: normal;
}
.mobile_only {
    display: none;
}

.screen-title {
    font-weight: normal;
    font-size: var(--marginS);
    color: var(--neutral);
}
.flow-title {
    font-weight: bold;
    margin: 0 0 var(--marginXS) 0;
    font-size: var(--marginS);
    color: var(--neutral);
}
.button-grid-title {
    margin: var(--marginS) 0 var(--marginS) 0;
}

.ordered-list,
.unordered-list {
    counter-reset: steps;
    list-style: none;
    padding-left: var(--marginXL);
}
.ordered-list > .list-item,
.unordered-list > .list-item {
    margin: var(--marginXL) 0 var(--marginXS) 0;
    counter-increment: steps;
    position: relative;
}
.ordered-list > .list-item::before,
.unordered-list > .list-item::before {
    top: -4px;
    content: '·';
    color: white;
    font-size: var(--marginS);
    line-height: var(--marginL);
    font-weight: bold;
    position: absolute;
    left: calc(-1 * var(--marginL) - var(--marginXS));
    width: var(--marginL);
    height: var(--marginL);
    background: var(--accent);
    border-radius: 50%;
    text-align: center;
}
.ordered-list > .list-item::before {
    content: counter(steps);
}
.ordered-list > .list-item:last-child::before {
    background: var(--selection);
}

.new-patient-question {
    margin: var(--marginL) 0;
}

.flow-content li {
    list-style: none;
}
.flow-screen-link {
    padding: 0;
    line-height: 1.3em;
    font-size: var(--marginS);
    font-weight: 600;
    background: none;
    border: none;
    color: var(--neutral);
    border-bottom: 2px solid var(--accent);
    text-align: left;
}
.flow-screen-link:not([disabled]) {
    cursor: pointer;
}
.flow-screen-link:not([disabled]):hover {
    border-color: var(--accent-hover);
}

.review {
    margin: var(--marginM) 0;
}

.button-grid {
    position: relative;
    display: grid;
    flex-wrap: wrap;
    list-style: none;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    gap: var(--marginXS);
}

.button-grid-item .button {
    font-variant-numeric: tabular-nums;
}

.button:not([disabled]):hover {
    background-color: var(--neutral-light);
}

.bottom-marginS {
    margin-bottom: var(--marginS);
}

.form-input {
    border: 1px solid var(--neutral-light);
    border-radius: var(--marginXS);
    padding: var(--marginXS);
}

.form-input.textfield {
    width: 100%;
    margin-bottom: var(--marginXS);
    font-size: var(--marginS);
    height: var(--marginXL);
    border: 2px solid var(--neutral-light);
    border-radius: var(--marginXS);
    padding: var(--marginXS);
    color: var(--neutral);
    box-sizing: border-box;
}
.form-input.validated {
    border: 2px solid var(--accent);
}

.form-input.submitted:not(.validated) {
    border: 2px solid var(--red-error);
}

.error-message {
    padding: var(--marginS);
    color: var(--red-error);
}

/* FLOW DISPLAY MANAGEMENT */

.healthcare_party_info {
    transition: var(--timing);
}

.change {
    margin-left: var(--marginM);
}

/*
.display-flow .address-block {
  transform:translateY(-4rem)

}

.display-flow .flow  {
  transform:translateY(-3rem)

}
*/

.information-box {
    margin-top: var(--marginM);
    padding: var(--marginM);
    transition: var(--timing);
}

@keyframes hideInfo {
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0;
        transform: translateY(10rem);
    }
    100% {
        display: none;
    }
}

/*
.display-flow .information-box{
  

 animation: hideInfo var(--timing) var(--easing) 0s forwards;
}
*/
.flow-header .nav {
    display: flex;
}

.flow-header .nav h2 {
    flex: 1;
}

.cancel-button {
    border: none;
    background: url('../img/close.svg');
    background-size: var(--marginL);
    background-repeat: no-repeat;
    width: var(--marginL);
    height: var(--marginL);
    transition: var(--timing);
    opacity: 1;
    z-index: 999;
    cursor: pointer;
}

@keyframes showFlow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.display-flow .flow {
    display: block;
    animation: showFlow var(--timing) var(--easing) var(--timing) forwards;
}

.display-flow .cancel-button {
    opacity: 0;
    pointer-events: all;
    animation: rotateCancel var(--timing) var(--easing) 0s forwards;
}
@keyframes rotateCancel {
    from {
        transform: rotateZ(-270deg);
        opacity: 0;
    }
    to {
        transform: rotateZ(-0deg);
        opacity: 1;
    }
}

.main-container.display-flow .start {
    opacity: 0;
}

/*
.display-flow .healthcare_party_info{
 opacity: 0;
  pointer-events: none;
}
*/

.main-container.display-flow .practice-title {
    opacity: 0;
    pointer-events: none;
}

.hcp-name {
    opacity: 0;
    transition: var(--timing);
    transition-delay: var(--timing);
    text-align: center;
    text-transform: uppercase;
}

/*
.display-flow .hcp-name{
  opacity: 1!important;
  transition-delay: 0;
  font-size: 1rem;
}
*/

.textfield.code-input {
    width: calc(100% - var(--marginM));
    text-align: center;
}

.button.start {
    width: 100%;
    display: none;
}

.button[disabled] {
    opacity: 0.5;
    cursor: auto;
}

.place-picker {
    display: flex;
    gap: 0;
    margin: 0 0 var(--marginS) 0;
    transition: 0.1s;
}

.place-picker div {
    padding: 0 var(--marginS);
    border-radius: 50vh;
    background-color: white;
    cursor: pointer;
    font-weight: 600;
    line-height: var(--marginL);
    color: var(--accent);
}

.place-picker div.selected {
    background-color: var(--selection);
    color: white;
    font-weight: 600;
}

.more-info {
    width: var(--marginS);
    height: var(--marginS);
    display: inline-block;
    margin-left: var(--marginS);
    text-align: center;
    position: relative;
    font-weight: 500;
}
.tooltip {
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
    width: 260px;
    position: absolute;
    transform: translate(0, -125%);
    background-color: #fff;
    border: 1px solid var(--neutral-lighter);
    padding: var(--marginM);
    border-radius: var(--marginXS);
    box-shadow:
        0px 27px 80px rgba(0, 0, 0, 0.07),
        0px 8.13971px 24.1177px rgba(0, 0, 0, 0.0456112),
        0px 3.38082px 10.0172px rgba(0, 0, 0, 0.035),
        0px 1.22278px 3.62304px rgba(0, 0, 0, 0.0243888);
}
.more-info:hover + .tooltip {
    opacity: 1;
}

.address-block {
    transition: 0.3s;
    background-color: var(--background);
    padding: var(--marginL);
    border-radius: var(--marginXL);
    display: flex;
    flex-direction: row;
    gap: var(--marginXL);
    justify-items: flex-start;

    /*box-shadow: 0 50px 60px rgb(12 25 39 / 10%), 0 16px 20px rgb(12 25 39 / 6%), 0 6px 8px rgb(12 25 39 / 5%);
  */
    /*
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07),
  0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
  0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
  0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
  0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
  0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
*/
}

textarea {
    height: calc(3 * var(--marginXL)) !important;
}

.contact-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    gap: var(--marginM);
}

.map {
    flex: 2;
}

.wrap {
    margin: var(--marginM);
}

.contact-details address,
.telecoms {
    flex: 1;
    display: flex;

    gap: var(--marginXS);
}

.telecoms {
    flex-direction: column;
}

@keyframes showFlowHeader {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes hideMainTitle {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.display-flow .flow-header {
    display: block;
}

.main-title {
    opacity: 1;
    animation: hideMainTitle var(--timing) var(--easing) 0 forwards;
}

header {
    min-height: 80px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-items: center;
}

.company {
    display: flex;
    flex-direction: row;
    gap: var(--marginS);
    align-items: center;
    flex: 1;
}
.name {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    text-transform: uppercase;
    line-height: 1.1rem;
    opacity: 0;
}

select[name='language'] {
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 600;
    color: var(--accent);
    text-transform: uppercase;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
}

.name span:first-of-type {
    font-weight: 600;
}

.name span:last-of-type {
    opacity: 0.4;
}
.logo {
    width: var(--marginXL);
    height: var(--marginXL);
}

.links {
    padding-inline-start: var(--marginL);
    margin-bottom: var(--marginM);
}

.telecoms li {
    list-style: none;
}
address svg,
.telecoms svg {
    width: var(--marginS);
    height: var(--marginS);
    display: inline;
}
.telecoms svg {
    margin-right: 4px;
}

address svg {
    margin-top: 4px;
}

.appointment {
    position: relative;
    border: 1px solid #e5e3ea;
    border-radius: 1rem;
    display: flex;
    padding: 1.5rem;
    align-items: center;
    margin: 3rem 0;
}

.appointmentDate {
    background: #ffffff;
    border: 1px solid #e5e3ea;
    box-shadow: 0 3px 0 #e5e3ea;
    border-radius: 0.7rem;
    width: 96px;
    height: 96px;
    text-align: center;
}

.appointmentDate .month {
    background: var(--selection);
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 0.7rem 0.7rem 0 0;
}

.appointmentDate .day {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 64px;
}

.appointment .details {
    text-align: left;
    margin-left: 2rem;
    flex: 1;
}

.appointment button {
    justify-self: flex-end;
}

.saving-appointment {
    padding: var(--marginM);
}

.flow-content p {
    margin: var(--marginM) 0;
}

.privacy .ordered-list {
    margin-top: var(--marginXL);
}

.privacy .ordered-list > .list-item::before {
    background-color: var(--selection);
}

a[target='_blank']::after {
    content: ' ↗';
    color: var(--accent);
}
a[target='_blank'] {
    color: var(--neutral);
}

.extra-button {
    margin-top: var(--marginXXL);
}

.loading {
    text-align: center;
    margin: 5rem;
}

.loading img {
    text-align: center;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.loading .message {
    animation: fade 3s infinite linear;
}

@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }
    5% {
        transform: scale(1.1);
    }
    39% {
        transform: scale(0.85);
    }
    45% {
        transform: scale(1);
    }
    60% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(0.9);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.alert {
    padding: var(--marginM);
    background-color: #FFFFCC;
    border-color: #E5E3EA;
}