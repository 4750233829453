body {
    font-family:
        'Poppins',
        -apple-system,
        BlinkMacSystemFont,
        'Helvetica Neue',
        sans-serif;
    color: var(--neutral);
    font-size: 1rem;
    line-height: 175%;
    font-weight: 300;
}

textarea {
    font-family:
        'Arial',
        -apple-system,
        BlinkMacSystemFont,
        'Helvetica Neue',
        sans-serif;
}

.warning {
    font-weight: 500;
    color: var(--destructive);
}

.main-title {
    transition: 0.3s;
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 110%;
    color: var(--neutral);
    margin-block-end: var(--marginS);
    margin-block-start: 0;
}

.main-subtitle {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    color: var(--neutral);
    margin-block-start: 0;
    margin-block-end: var(--marginXS);
}

.medium-title {
    font-style: normal;
    font-weight: bold;
    font-size: 1.75rem;
    margin-block-start: 0;
}
.screen-title {
    font-weight: normal;
    margin-bottom: var(--marginL);
    font-size: var(--marginS);
    color: var(--neutral);
    margin-block-end: var(--marginXS);
}
.flow-title {
    font-weight: bold;
    margin: 0 0 var(--marginXS) 0;
    font-size: var(--marginS);
    color: var(--neutral);
}

.small-text {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: var(--neutral-secondary);
    font-weight: 500;
}

.light {
    font-weight: 300;
}

address strong {
    text-transform: uppercase;
}
