@media screen and (min-width: 480px) {
    footer > div {
        display: flex;
        gap: var(--marginXXL);
        text-align: left;
    }
}
@media screen and (min-width: 960px) {
    .hcp-name {
        display: none;
    }
    .button.start {
        display: none;
    }

    .wrap {
        margin: var(--marginM) auto;
        position: relative;
        max-width: 960px;
        width: 100%;
    }

    .name {
        opacity: 1;
    }

    .tooltip {
        transform: translate(var(--marginXL), -125%);
    }

    @keyframes hideMap {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    .map {
        flex: 2;
        display: block;
        min-height: 320px;
        outline: 2px solid white;
        border-radius: 0 var(--marginL) var(--marginL) 0;
        opacity: 1;
        animation: hideMap var(--timing) var(--easing) 0 forwards;
    }

    .map.collapsed {
        opacity: 0;
        display: none;
    }
}
